  
  
  .highlighted-number {
    color: blue;
    font-size: 1em;
    font-weight: bold;
  }

.highlighted-totals-blue {
    color: blue;
    font-size: 1em;
    font-weight: bold;
  }

.highlighted-totals-red {
    color: red;
    font-size: 1em;
    font-weight: bold;
  }