body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .ant-layout-header {
    display: flex;
    align-items: center;
  }
  
  .ant-layout-content {
    padding: 20px;
  }
  