.container{
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 20px; */
    max-width: 1300px;
  width: 100%;
}

.blog-post{
    position: relative;
}

.blog-post img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
}

.blog-post .category{
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 15px;
    font-size: 14px;    
    text-decoration: none;
    background-color: #1976d2;
    color: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.1);
    text-transform: uppercase;
}

.blog-post .categoryFail{
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 15px;
    font-size: 14px;    
    text-decoration: none;
    background-color: #e52d5e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.1);
    text-transform: uppercase;
}

.text-content{
    position: absolute;
    bottom: -40px;
    padding: 40px;
    background-image: linear-gradient(90deg, rgb(251, 250, 255) 0%, rgba(229,239,241,0.5844931722689075) 94%, rgb(162, 183, 187) 100%);
    width: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.08);
    padding-top: 10px;
}

.text-content h2{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
}

.text-content img{
    height: 70px;
    width: 70px;
    border: 5px solid rgba(0,0,0,0.1);
    border-radius: 50%;
    position: absolute;
    top: -35px;
    left: 35px;
}

.tags a{
    color: #888;
    font-weight: 700;
    text-decoration: none;
    margin-right: 15px;
    transition: 0.3s ease;
}

.tags a:hover{
    color: #000;
}

@media screen and (max-width: 1100px) {
    .container{
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 60px;
    }
}

@media screen and (max-width: 600px) {
    .container{
        grid-template-columns: 1fr;
        grid-row-gap: 60px;
    }
}