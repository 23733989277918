  .small-card {
    position: relative;
    background: #f9f9fc;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: 250px; /* Tamanho fixo mais compacto */
    height: 170px; /* Altura fixa */
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    border-left: 2px solid #000; /* Borda fina preta na direita */
  }

  .small-card-red {
    position: relative;
    background: #f9f9fc;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: 250px; /* Tamanho fixo mais compacto */
    height: 150px; /* Altura fixa */
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    border-left: 2px solid #af0303fd; /* Borda fina preta na direita */
  }

  
  .small-card:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .small-card-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Adiciona uma transição suave */
  }

  .small-card-close:hover {
    transform: scale(1.1); /* Aumenta o botão em 10% */
    opacity: 0.8; /* Reduz a opacidade para 80% */
  }
  
  
  .small-card-content {
    padding: 12px;
  }
  
  .small-card-header {
    margin-bottom: 8px;
  }
  
  .small-card-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 4px; /* Espaço entre título e subtítulo */
  }
  
  .small-card-subtitle {
    font-size: 12px;
    color: #777;
  }
  
  .small-card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .small-card-body-content {
    font-size: 12px;
    color: #555;
    max-height: 40px; /* Altura fixa */
    overflow-y: auto; /* Rolagem quando o conteúdo exceder o tamanho */
  }
  
  .small-card-value {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    gap: 4px;
  }
  